<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="require('@/assets/images/logos/logo.svg')"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              Hub
            </h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="mb-2">
            Please sign-in to your account
          </p>

          <div
            v-if="has_error"
            class="alert alert-danger"
          >
            <p>Error, unable to log in with these credentials.</p>
          </div>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form
            method="post"
            @submit.prevent="login"
          >
            <v-text-field
              v-model="email"
              outlined
              label="Email"
              placeholder="john@example.com"
              hide-details
              required
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-model="password"
              required
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Password"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <vue-recaptcha
              :theme="$vuetify.theme.dark ? 'dark' : 'light'"
              ref="recaptcha"
              class="mt-3"
              :sitekey="recaptcha"
              :load-recaptcha-script="true"
              type="invisible"
              @verify="onCaptchaVerified"
              @expired="onCaptchaExpired"
            >
            </vue-recaptcha>

            <div class="d-flex align-center justify-space-between flex-wrap">
              <v-checkbox
                label="Remember Me"
                hide-details
                class="me-3 mt-1"
              >
              </v-checkbox>

              <!-- forgot link -->
              <a
                href="javascript:void(0)"
                class="mt-1"
              >
                Forgot Password?
              </a>
            </div>

            <v-btn
              block
              color="primary"
              type="submit"
              class="mt-6"
            >
              Login
            </v-btn>
          </v-form>
        </v-card-text>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <span class="me-2">
            New on our platform?
          </span>
          <router-link :to="{name:'pages-register'}">
            Create an account
          </router-link>
        </v-card-text>

        <!-- divider -->
        <v-card-text class="d-flex align-center mt-2">
          <v-divider></v-divider>
          <span class="mx-5">or</span>
          <v-divider></v-divider>
        </v-card-text>

        <!-- social links -->
          <v-card-actions class="d-flex justify-center">
            <v-btn
              v-for="link in socialLink"
              :key="link.icon"
              icon
              class="ms-1"
              @click="authProvider(link.provider)"
            >
              <v-icon :color="$vuetify.theme.dark ? link.colorInDark : link.color">
                {{ link.icon }}
              </v-icon>
            </v-btn>
          </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { VueRecaptcha } from 'vue-recaptcha'

export default {
  components: {
    'vue-recaptcha': VueRecaptcha,
  },
  data() {
    const socialLink = [
      // {
      //   icon: mdiFacebook,
      //   color: '#4267b2',
      //   colorInDark: '#4267b2',
      // },
      // {
      //   icon: mdiTwitter,
      //   color: '#1da1f2',
      //   colorInDark: '#1da1f2',
      // },
      // {
      //   icon: mdiGithub,
      //   color: '#272727',
      //   colorInDark: '#fff',
      // },
      {
        provider: 'google',
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    return {
      email: null,
      password: null,
      has_error: false,
      recaptcha: process.env.VUE_APP_RECAPTCHA_KEY,

      isPasswordVisible: false,
      socialLink,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiGoogle,
      },
    }
  },

  watch: {

  },

  methods: {
    authProvider(provider) {
      this.$auth.oauth2(provider, {})
    },

    async login() {
      const app = this
      try {
        let res = await this.$auth.login({
          params: {
            email: app.email,
            password: app.password,
            recaptcha: app.recaptcha,
          },
          success() {
            app.$router.push({ name: 'dashboard' })
            app.onCaptchaExpired()
          },
          error() {
            app.error = res.data
            app.has_error = true
          },
          rememberMe: true,
          fetchUser: true,
        })
      } catch (e) {
        let errMsg = 'Something gone wrong'
        if (e.response.data.error === 'login_error') {
          errMsg = 'Email or password incorrect'
        }
        await app.$store.dispatch('addAlertMsg', {
          text: errMsg,
          type: 'error'
        })
      }
    },
    onCaptchaVerified(recaptchaToken) {
      this.recaptcha = recaptchaToken
      this.validateCaptcha = true
    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset()
    },
  },
  mounted() {
    if (this.$route.params.provider && this.$route.query.code) {
      // @TODO: show loader
      const provider = this.$route.params.provider
      this.$auth.oauth2(provider, {
        url: '/auth/social-login/' + provider,
        code: true,
        data: {
          code: this.$route.query.code
        },
        state: this.$route.query.state
      })
    }
  }
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
